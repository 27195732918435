<template>

	<TopMenu :breadcrumbs="breadcrumbs"/>

	<Navigator />

	<div class="component-filter">
		<div class="range-picker">
			<DatePicker v-model="filter.begin" label="Дата от" :isClear="false" />
			<DatePicker v-model="filter.end" label="Дата до" :isClear="false" />
		</div>
		<div class="btn-group">
			<a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
				<div class="btn--text"> Применить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
			<a class="btn btn--medium btn--outline btn--inline" @click="clearFilter">
				<div class="btn--text"> Сбросить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
		</div>
	</div>

	<div class="load-container" :class="{'load' : isLoad}">

		<template v-if="data !== null">
			
			<div class="block mt-1" >
				<div class="block__body">
					<div class="file">
						<img class="file__ico" src="@/assets/img/file-blue.svg">
						<div class="file__info">
							<div class="file__name">{{ data.name }}</div>
							<div class="file__bottom">
								<a class="file__link" :href="data.link">
									<span> Скачать </span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="block block--table mt-1" >
				<div class="block__body">
					<table class="table-custom">
						<thead>
							<tr>
								<th>Направление обслуживания</th>
								<th>Кол-во работ / осмотров</th>
							</tr>
						</thead>
						<tbody>

							<template v-for="(module, index) in data.tableData" :key="index">
								
								<tr class="c-module-row">
									<td> {{ module.moduleName }} </td>
									<td> {{ module.moduleCount }} </td>
								</tr>

								<tr class="c-topic-row" v-for="(topic, idx) in module.topics" :key="idx">
									<td> {{ topic.name }} </td>
									<td> {{ topic.value }} </td>
								</tr>

							</template>
							<tr class="c-total-row">
								<td>Итого</td>
								<td>{{ data.tableTotal }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>



		</template>


  	<div class="empty-data" v-if="data== null || data.link == null || data.tableData == null">
  		Нет данных
  	</div>

	</div>

</template>

<script setup>
	import TopMenu from '@/components/TopMenu.vue';
	import Navigator from '@/components/reports/Navigator.vue';
	import DatePicker from '@/components/ux/DatePicker.vue';
</script>

<script>
  export default {
    
    name: 'PageReportsCompleteHouse',
    

    data: () => ({
      	isLoad: false,
      	breadcrumbs: [],
      	house: null,

      	filter: {
	      	begin: null,
	      	end: null,
      	},
      	data: null,
    }),
    
    computed: {
    	house_id () {
    		return this.$route.params.house_id;
    	},
    },

    created () {

    	this.filter.begin = this.$moment().startOf('year').format('YYYY-MM-DD');
    	this.filter.end = this.$moment().format('YYYY-MM-DD');

    	this.loadData();

    	Promise.all([this.loadHouse()])
		    .then(() => {
		      this.beforeRender();
		    })
		    .catch((e)=> {
		     	console.log(e);
		    })
		    .finally(()=> {
		      
		    })
    },
    methods: {

    	beforeRender () {
    		this.breadcrumbs = [
	        	{name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico},
	        	{name: this.house.address, link: ''},
	        ];
    	},

    	async clearFilter (e) {
	 			const btn = e.currentTarget;
	 			btn.classList.add('btn--load');
				this.filter.begin = this.$moment().startOf('year').format('YYYY-MM-DD');
				this.filter.end = this.$moment().format('YYYY-MM-DD');
				await this.loadData();
	 			btn.classList.remove('btn--load');
    	},

    	async saveFilter (e) {
	 			const btn = e.currentTarget;
	 			btn.classList.add('btn--load');
				await this.loadData();
	 			btn.classList.remove('btn--load');
    	},

    	buildfilter () {
    		const param = new URLSearchParams({});
    		if(this.filter.begin !== null) {
        		param.set("dateFrom", this.filter.begin + 'T00:00:00.000000+00:00');
	        }
	        if(this.filter.end !== null) {
	        	param.set("dateTo", this.filter.end + 'T23:59:59.999999+00:00');
	        }
	        return param;
    	},

    	async loadHouse () {
      		this.house = await this.$store.dispatch('getHouse', {house_id: this.house_id});
    	},

    	async loadData () {
    		this.isLoad = true;
	      const data = this.buildfilter();
	      this.data = await this.$store.dispatch('getReportsHousePlan', {house_id: this.house_id, data: data});
    		this.isLoad = false;
    	},
    
    }
  }
</script>


<style scoped>
	
	.component-filter {
			background-color: #fff;
			padding: 15px;
			border-radius: 8px;
			display: flex;
	    flex-direction: row;
	    align-items: center;
	    gap: 10px;
	    justify-content: space-between;
	    position: relative;
	    margin-bottom: 10px;
	}
	.component-filter__item {
		flex: 1 1 0;
  		width: 0;
	}
	.component-filter a {
		width: fit-content;
	}

	.block__body {
	    padding: 20px 30px;
	}
	.block__header {
		font-size: 20px;
	}
	.block__body .file:not(:last-child) {
		margin-bottom: 20px;
	}

	.block--table .block__body {
		padding: 0px;
	}
	.c-topic-row td:first-child {
		padding-left: 40px;
	}
	.c-module-row {
		background-color: #F4F8FA;
	}
	.c-module-row td {
		font-weight: 600;
	}
	.c-total-row td {
		font-weight: 700;
	}

</style>