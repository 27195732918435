<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<div class="panels" v-if="data !== null && data.employees.length > 0">
			<div class="block block--medium block--border" v-for="(data, index) in dataset.commandants" :key="index">
				<div class="block__header">
					Комендант / управляющий
				</div>
				<div class="block__body">
					<div class="block__left">
						<LineChart :chartData="data" :options="options" cssClasses="chart"/>
					</div>
					<div class="block__right">
						<div class="c-legend">
		                    <div class="c-legend__item" v-for="(item, idx) in data.datasets" :key="idx">
		                        <div class="c-legend__badge" :style="{backgroundColor: item.pointBackgroundColor}"></div> 
		                        {{ item.label }}
		                    </div>
		                </div>
					</div>
				</div>
			</div>
			<div class="block block--medium block--border" v-for="(data, index) in dataset.cleaners" :key="index">
				<div class="block__header">
					Клининг
				</div>
				<div class="block__body">
					<div class="block__left">
						<LineChart :chartData="data" :options="options" cssClasses="chart"/>
					</div>
					<div class="block__right">
						<div class="c-legend">
		                    <div class="c-legend__item" v-for="(item, idx) in data.datasets" :key="idx">
		                        <div class="c-legend__badge" :style="{backgroundColor: item.pointBackgroundColor}"></div> 
		                        {{ item.label }}
		                    </div>
		                </div>
					</div>
				</div>
			</div>
			<div class="block block--medium block--border" v-for="(data, index) in dataset.others" :key="index">
				<div class="block__header">
					Технический специалист
				</div>
				<div class="block__body">
					<div class="block__left">
						<LineChart :chartData="data" :options="options" cssClasses="chart"/>
					</div>
					<div class="block__right">
						<div class="c-legend">
		                    <div class="c-legend__item" v-for="(item, idx) in data.datasets" :key="idx">
		                        <div class="c-legend__badge" :style="{backgroundColor: item.pointBackgroundColor}"></div> 
		                        {{ item.label }}
		                    </div>
		                </div>
					</div>
				</div>
			</div>
		</div>

		<div class="empty-data" v-else>
		  	Нет данных
		</div>

	</div>
</template>

<script setup>
    import { LineChart } from 'vue-chart-3';
    import { Chart, registerables} from 'chart.js';
	Chart.register(...registerables);
</script>

<script>
  	export default {
	    name: 'EmployersAnalyticsLine',
	    props: ['organization_id'],
        components: { LineChart },
	    data: () => ({
	    	isLoad: true,
	    	data: null,
	    	dataset: {
	        	commandants: [],
	        	cleaners: [],
	        	others: [],
	        },
	    	options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                interaction: {
                  mode: 'nearest',
                  axis: 'x',
                  intersect: false
                },
                scales: {
                    x: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 0,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    },
                    y: {
                        // offset: true,
                        suggestedMin: 0,
      					suggestedMax: 100,
                        ticks: {
                            font: {
                                size: 10,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    }
                }
            },
	    }),

	   	created () {
	      	Promise.all([this.loadData()])
	      		.then(() => {
	      		})
	      		.catch((e)=> {
	        		console.log(e);
	      		})
	      		.finally(() => {
	      			this.isLoad = false;
		  		});
	    },

	    methods: {
	    	getColorByIndex (index) {
	    		switch (index) {
	    			case 0:
	    				return '#008dd2';
	    			case 1:
	    				return '#e03712';
	    			case 2:
	    				return '#ff9100';
	    			case 3:
	    				return '#7ecd77';
	    			case 4:
	    				return '#750000';
	    		}
	    	},

	    	separateData (items, link, labels) {
	    		// Сотрудники в категории Комменданты
		        for (let i = 0; i < items.length; i += 5) {
				    // Массив из 5 сотрудников в категории
		        	const subArray = items.slice(i, i + 5);
		        	// График
		        	const currDatasets = { labels: labels, datasets: [] }
		        	// Наполняем график сотрудником
		    		subArray.forEach((item, index) => {
					    // Цвета по индексам
				    	const color = this.getColorByIndex(index);

				    	let fio = item.lastName.charAt(0).toUpperCase() + item.lastName.slice(1);

				    	if(item.firstName !== null) {
				    		fio += ' ' + item.firstName.trim().charAt(0).toUpperCase() + '.';
				    	}
				    	
				    	if(item.middleName !== null) {
				    		fio += ' ' + item.middleName.trim().charAt(0).toUpperCase() + '.';
				    	}

				    	// Пушим
				    	currDatasets.datasets.push({
		                    label: fio,
		                    data: item.persents,
		                    fill: false,
		                    borderColor: color + '80',
		                    pointStyle: 'circle',
		                    pointRadius: 2,
		                    pointHoverRadius: 4,
		                    pointBackgroundColor: color,
		                    borderWidth: 1.5,
		                    tension: 0.4,
		                    isVisible: true,
		                    disabledLabel: false,
		                });
				    });
		    		// Записываем в результат
				    link.push(currDatasets);
		        }
	    	},

	    	issueFunc (functions, function_id) {

	    		const idx = functions.findIndex((item) => {
					
					return item.id == function_id

	    		});

	    		return idx !== -1;

	    	},


	    	async loadData () {
		        
		        this.data = null;

		        // Подгружаем контент
		        const { data } = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/diagram-empl-director?company_id=' + this.organization_id);
		        
		        this.data = data;

		        // Обнуляем значения
		        this.dataset = {
		        	commandants: [],
		        	cleaners: [],
		        	others: [],
		        };

		        // Категории
		        const commandants = [];
		        const cleaners = [];
		        const others = [];

		        // Делим сотрудников по категориям
		        data.employees.forEach((item) => {
		        	// Комендант
		        	if(this.issueFunc(item.functions, 1) ) {
		        		commandants.push(item);
		        		return;
		        	// Дворник или Клинер
		        	} else if (this.issueFunc(item.functions, 4) || this.issueFunc(item.functions, 6)) {
		        		cleaners.push(item);
		        		return;
		        	// Все остальные - тех. специалисты
					} else {
		        		others.push(item);
		        		return;
					}
		        })

		        // Собираем график Комендантов
		        this.separateData(commandants, this.dataset.commandants, data.dates);
		        // Собираем график Уборщиков
		        this.separateData(cleaners, this.dataset.cleaners, data.dates);
		        // Собираем график Остальных
		        this.separateData(others, this.dataset.others, data.dates);

	    	}
	    }
	}
</script>

<style scoped>

	.c-legend {
		display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: flex-start;
	    width: 130px;
	}
	.c-legend__item {
		cursor: unset;
	}
	.panels {
		display: flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	padding: 20px;
	}

	.block {
	    width: calc(50% - 5px);
/*	    border: 1px solid var(--cozh-light-grey);*/
	}
	.block__body {
	    display: flex;
	    flex-direction: row;
	    align-items: flex-start;
	    justify-content: flex-start;
	}
	.block__left {
		width: 100%;
	    padding: 20px 0px 20px 20px;
	    border: none;
	}
	.block__right {
	    padding: 20px;
    	width: fit-content;
	} 
	.chart {
		height: 300px;
	}

</style>
