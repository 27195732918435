<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<grid-table
			:options="{
        fields: [
        	{key: 'house', title: 'Адрес'},
        	{key: 'emplLastName', title: 'Управляющий домом (ФИО)'},
        	{key: 'totalTasks', title: 'Всего заданий (кол-во)'},
        	{key: 'donePercent', title: 'Сделано,&nbsp;%'},
        	{key: 'issuePercent', title: 'Сделано, есть проблема,&nbsp;%'},
        	{key: 'failedPercent', title: 'Не сделано,&nbsp;%'},
        	{key: 'expiredPercent', title: 'Просрочено,&nbsp;%'},
        ],
	      indexField: '№',
	    	defaultSort: {
	    		key: 'donePercent',
	    		sort: 'desc',
	    	},
	      items: items,
			}"
		>
	        <template v-slot:default="{ item }">
	            <td> <span class="nowrap"> {{ item.house }} </span> </td>
	            <td> <span class="nowrap"> {{ buildFio(item) }} </span> </td>
          		<td> <span class="number"> {{item.totalTasks}} </span> </td>
	            <td> <span class="number number--blue"> {{item.donePercent + '%'}} </span> </td>
	            <td> <span class="number number--orange"> {{item.issuePercent + '%'}} </span> </td>
	            <td> <span class="number number--red"> {{item.failedPercent + '%'}} </span> </td>
	            <td> <span class="number number--dark-red"> {{item.expiredPercent + '%'}} </span> </td>
	        </template>
	    </grid-table>

	</div>
</template>

<script setup>
  import GridTable from '@/components/ux/FastGridTable.vue';
</script>

<script>
  	export default {
	    
	    name: 'HousesInfo',
	    
	    props: ['organization_id', 'begin', 'end'],

        expose: ['loadData'],

	    data: () => ({
	    	isLoad: true,
	    	items: [],
	    }),

	    created () {
	    	this.loadData();
	    },


	    computed: {

	    	buildFio () {
	    		return (item) => {

	  				let fio = '';

	  				// Фамилия с большой буквы
	  				if(item.emplLastName !== null && item.emplLastName.length !== 0) {
	  					fio += item.emplLastName.charAt(0).toUpperCase() + item.emplLastName.slice(1).trim();
	  				}
	  				// Первая буква имени - большая
	  				if(item.emplFirstName !== null && item.emplFirstName.length !== 0) {
	  					fio += ' ' + item.emplFirstName.trim().charAt(0).toUpperCase() + '.';
	  				}
	  				// Первая буква отчества - большая
	  				if(item.emplMiddleName !== null && item.emplMiddleName.length !== 0) {
	  					fio += ' ' + item.emplMiddleName.trim().charAt(0).toUpperCase() + '.';
	  				}

	    			return fio;
	    		}
	    	},

	    },

	    methods: {

	    	buildfilter () {
		        const param = new URLSearchParams({});
		        param.set("company_id", this.organization_id);
		        if(this.begin !== null) {
		          param.set("begin", this.begin + 'T00:00:00.000000+00:00');
		        }
		        if(this.end !== null) {
		          param.set("end", this.end + 'T23:59:59.999999+00:00');
		        }
		        return param;
		    },

	    	async loadData () {
	    		this.isLoad = true;
	    		const param = this.buildfilter();
		        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/report-house-director?' + param);
		        this.items = data.houses;
	    		this.isLoad = false;
	    	},

	    }
	}
</script>

<style scoped>
	.number {
		font-weight: 600;
	}
	.number--orange {
		color: var(--cozh-orange);
	}
	.number--blue {
		color: var(--cozh-blue);
	}
	.number--red {
		color: var(--cozh-red);
	}
	.number--dark-red {
		color: var(--cozh-dark-red);
	}
</style>
