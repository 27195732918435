import { createStore } from 'vuex'
import axios from 'axios'

// admin
// AuFsgbk3crq9

// Create a new store instance.
export default createStore({

  state: {
    api_endpoint: process.env.VUE_APP_API_ENDPOINT,
    auth: {
      token: localStorage.getItem('token') || '',
      user: JSON.parse(localStorage.getItem('user')) || {},
    },
    functions: null,
    roles: null,
    regions: null,
    moTypes: null,
    cityTypes: null,
    cities: null,
    modules: null,
    location_types: null,
  },

  getters : {
    // bool статус авторизации
    isLoggedIn: state => !!state.auth.token,
    // Текущий пользователь
    authUser: state => state.auth.user,
  },

  mutations: {
    // Устанавливаем авторизацию
    setAuth(state, {user, token}) {
      // Устанавливаем access_token
      state.auth.token = token;
      // Устанавливаем пользователя
      state.auth.user = user;
    },
    logout(state) {
      // Сбрасываем access_token
      state.auth.token = '';
    },
    setFunctions(state, { functions }) {
      state.functions = functions;
    },
    setRoles(state, { roles }) {
      state.roles = roles;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    },
    setMoTypes(state, { moTypes }) {
      state.moTypes = moTypes;
    },
    setCityTypes(state, { cityTypes }) {
      state.cityTypes = cityTypes;
    },
    setCities(state, { cities }) {
      state.cities = cities;
    },
    setModules(state, { modules }) {
      state.modules = modules;
    },
    setLocationTypes(state, { location_types }) {
      state.location_types = location_types;
    },
  },

  actions: {

    // Список типов локаций
    async getLocationTypes ({commit, state}) {
      if (state.location_types === null) {
        const r = await axios.get(state.api_endpoint + '/locationType/');
        const location_types = r.data.data;
        commit('setLocationTypes', { location_types });
      }
    },
    // Список ролей системы
    async getRoles ({commit, state}) {
      if (state.roles === null) {
        const r = await axios.get(state.api_endpoint + '/roles/');
        const roles = [];
        r.data.data.forEach((item) => {
          roles.push({ id: item.name, name: item.description });
        });
        commit('setRoles', { roles });
      }
    },
    // Список квалификаций системы
    async getFunctions ({commit, state}) {
      if (state.functions === null) {
        const r = await axios.get(state.api_endpoint + '/functions/');
        const functions = [];
        r.data.data.forEach((item) => {
          functions.push({ id: item.id, name: item.description });
        });
        commit('setFunctions', { functions });
      }
    },

    // Список регионов
    async getRegions ({commit, state}) {
      if (state.regions === null) {
        const r = await axios.get(state.api_endpoint + '/regions');
        const regions = r.data.data
        commit('setRegions', { regions });
      }
    },
    // Список типов муниципальных округов
    async getMoTypes ({commit, state}) {
      if (state.moTypes === null) {
        const r = await axios.get(state.api_endpoint + '/moTypes');
        const moTypes = r.data.data
        commit('setMoTypes', { moTypes });
      }
    },
    // Список типов городов
    async getСityTypes ({commit, state}) {
      if (state.cityTypes === null) {
        const r = await axios.get(state.api_endpoint + '/city-type');
        const cityTypes = r.data.data
        commit('setCityTypes', { cityTypes });
      }
    },

    // Список городов
    async getCities ({commit, state}) {
      if (state.cities === null) {
        const r = await axios.get(state.api_endpoint + '/cities');
        const cities = r.data.data
        commit('setCities', { cities });
      }
    },

    // Список модулей
    async getModules ({commit, state}) {
      if (state.modules === null) {
        const r = await axios.get(state.api_endpoint + '/technique/module?detailed=true');
        const modules = r.data.module
        commit('setModules', { modules });
      }
    },



    async getTrackerObjects ({state}) {
      const {data} = await axios.get(state.api_endpoint + '/tracker/counter');
      return data;
    },
    async getTrackerTasks ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/tracker/' + param.house_id + '/tasks?' + param.data);
      return data;
    },
    async getHouse ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/houses/' + param.house_id);
      return data;
    },
    async getReportsCompany ({state}) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/company');
      return data.companies;
    },
    async getReportsHousePie ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/tasks/pie/' + param.house_id + '?' + param.data);
      return data;
    },
    async getReportsHouseEmployees ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/tasks/detailed/' + param.house_id + '?' + param.data);
      return data.employee;
    },
    async getReportsHouseDynamics ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/tasks/dynamics/' + param.house_id + '?' + param.data);
      return data;
    },
    async getReportsHouseComplete ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/tasks/progress/' + param.house_id + '?' + param.data);
      return data.modules;
    },
    async getReportsHousePlan ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/document/report-upcoming-jobs/' + param.house_id + '?' + param.data);
      return data;
    },
    async getReportsHouse ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/document/report/' + param.house_id + '?' + param.data);
      return data.reports;
    },
    async getReportsHouseMonth ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/document/report-month/' + param.house_id + '?' + param.data);
      return data;
    },
    async getAnaliticsSystemModules ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleReviewResult ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/review/' + param.module_id + '/results?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleReviewIssuelist ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/review/' + param.module_id + '/issueList?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleReviewIssueNum ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/review/' + param.module_id + '/issueNum?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleReviewRaised ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/review/' + param.module_id + '/issueWorkRaised?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleWorkResult ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/work/' + param.module_id + '/results?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleWorkIssuelist ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/work/' + param.module_id + '/controlIssueList?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleWorkPassedNum ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/work/' + param.module_id + '/controlPassedNum?' + param.data);
      return data;
    },
    async getAnaliticsSystemModuleWorkIssueNum ({state}, param) {
      const {data} = await axios.get(state.api_endpoint + '/dashboard/module/' + param.house_id + '/work/' + param.module_id + '/controlIssueNum?' + param.data);
      return data;
    },



    // Выход из системы
    async logout({commit}) {
      commit('logout');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];
      return true;
    },

    // Авторизация
    async login({commit, state}, param ) {
      // Перед авторизацией удаляем старый токен доступа
      localStorage.removeItem('token');
      try {
        // Запрос авторизации
        const tokenResponse = await axios.post(state.api_endpoint + '/token', param, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Полученный токен
        const token = tokenResponse.data.access_token;
        // Axios по умолчанию будет отправлять авторизацию с запросами
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // Что за пользователь?
        const meResponse = await axios.get(state.api_endpoint + '/employees/me');
        // Полученный объект пользователя
        const user = meResponse.data;

        // Пользователь не активный - непускаем
        if (user.active === false) {
          // Ошибку на форму
          return {status: false, msg: 'Учётная запись деактивирована'};
        }

        // Для роли staff нет доступа в систему
        if (user.roleId === 'staff') {
          // Ошибку на форму
          return {status: false, msg: 'Для данной учётной записи доступ закрыт'};
        }

        // В local storage устанавливаем user
        localStorage.setItem('user', JSON.stringify(user));

        // Авторизация успешна
        commit('setAuth', {user, token});

        // В local storage устанавливаем token
        localStorage.setItem('token', token);
        
        // Всё ок на форму
        return {status: true, msg: 'Успешная авторизация'};
      
      } catch (e) {
      
        // Неверный логин / пароль
        if(e.response.status == 401) {
          // Ошибку на форму
          return {status: false, msg: 'Неверный логин или пароль'};
        }
      
      }
      
      // Ошибку на форму если не поймал раньше
      return {status: false, msg: 'Неизвестная ошибка авторизации'};
    
    },

  },

});