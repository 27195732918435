<template>
	<table class="table-custom">
		<thead>
	      	<tr>

	      		<th v-if="options.indexField"> 
	      			<div class="grid-order"> {{ options.indexField }}</div>
	      		</th>

	        	<th v-for="(field, index) in displayHeader" :key="index">
	        		<div class="grid-order" :class="orderColamnClass(field)" @click="setOrder(field)" v-if="field.key">
	        			<div class="grid-order__column" v-html="field.title"></div>
	        			<div class="grid-order__container">
	        				<svg class="grid-order__control" width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 4L3.5 1.5L6 4" stroke="#AAB4B9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<svg class="grid-order__control" width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 1L3.5 3.5L6 1" stroke="#AAB4B9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
	        			</div>
	        		</div>
	        		<div class="grid-order" v-html="field.title" v-else></div>
	        	</th>
	      	</tr>
      	</thead>
      	<tbody class="load-container">
			<template v-if="displayItems.length > 0">
  				<template v-for="(item, index) in displayItems" :key="index">
					<tr>
  						<td v-if="options.indexField"> {{index + 1}} </td>
						<slot 
							:item="item"
							:key="index"
							:index="index + 1"
							:params="params">
							<!-- slot content in parent -->
						</slot>
          			</tr>
  				</template>
			</template>
			<div v-else class="grid-empty">
				Список пуст...
			</div>
      </tbody>
	</table>
</template>

<script setup>
	import _ from 'lodash';
</script>

<script>
	
	export default {
		
		name: 'FastGridTable',

		props: ['options'],

	    data: () => ({
  			params: {
			  	order: {
			  		key: null,
			  		sort: null,
			  	},
			},
	    }),

	    created () {

	    	if(this.options.defaultSort) {
	    		this.params.order = this.options.defaultSort;
	    	}

	    },

	    computed: {
	    	
	    	displayHeader () {

	    		let items = [ ... this.options.fields ];

	    		// // Добавляем нумерацию если необходимо
	    		// if(this.options.indexField) {
	    		// 	items.unshift({key: 'index', title: this.options.indexField});
	    		// }

	    		return items;
	    	},

	    	displayItems () {

	    		// Список оригинальный
	    		let items = [ ... this.options.items ];

	    		// // Добавляем нумерацию если необходимо
	    		// if(this.options.indexField) {
	    		// 	for (var i = 0; i <= items.length - 1; i++) {
	    		// 		items[i].index = i + 1;
	    		// 	}
	    		// }

	    		// Сортируем по полю
	    		if(this.params.order.key !== null) {
					items = _.orderBy(
						items, 
						[item => typeof item[this.params.order.key] === 'string' ? item[this.params.order.key].toLowerCase() : item[this.params.order.key] ], 
						this.params.order.sort
					);
	    		}

	    		return items;
	    	},

	    	orderColamnClass () {
	  			return (field) => {
	  				if(this.params.order.key == field.key) {
	  					return 'grid-order--' + this.params.order.sort;
	  				}
	  			}
	  		},
	    },

	    methods: {

	    	setOrder (field) {
	    		if (this.params.order.key == field.key) {
	    			if(this.params.order.sort == 'desc') {
	    				this.params.order.sort = 'asc';
	    			} else {
	    				this.params.order.key = null;
	    				this.params.order.sort = null;
	    			}

	    		} else {
	    			this.params.order.key = field.key;
	    			this.params.order.sort = 'desc';
	    		}
	  		},
	    
	    },

	}

</script>


<style scoped>

	.table-custom th {
		padding: 0px;
	}
	.checkbox label {
		white-space: nowrap;
	}
	.table-responsive {
    	border-bottom: 1px solid var(--cozh-light-grey);
	}


	.table-custom thead tr th:last-child {
	    padding-right: 0px;
	}
	.table-custom thead tr th:first-child {
	    padding-left: 0px;
	}

	.table-custom thead tr th:first-child .grid-order {
		padding-left: 25px;
	}
	.table-custom thead tr th:last-child .grid-order {
		padding-right: 25px;
	}


	.grid-order {
		display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	    cursor: pointer;
	    padding: 13px 15px;
	    height: 100%;
	}

	.grid-order__column {
		margin-right: 10px;
		user-select: none;
		transition: color .2s ease-out;
		font-weight: 600;
	}

	.grid-order__container {
		display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: space-between;
	    height: 13px;
	}

	.grid-order__control path {
		transition: all .2s ease-out;
	}

	.grid-order--asc .grid-order__container 
	.grid-order__control:first-child path {
		stroke: var(--cozh-black);
	}

	.grid-order--desc .grid-order__container .grid-order__control:last-child path {
		stroke: var(--cozh-black);
	}

	.grid-order--asc .grid-order__column, 
	.grid-order--desc .grid-order__column {
		color: var(--cozh-black);
	}

	.grid-order:hover .grid-order__column {
		color: var(--cozh-blue);
	}





	.grid-pager {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 15px;
		padding-right: 25px;
	}
	.grid-pagination {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.grid-pagination__item {
		height: 30px;
		width: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 600;
		color: var(--cozh-dark-grey);
		user-select: none;
		line-height: 1;
	}
	.grid-pagination__item--current {
		color: var(--cozh-black);
	} 
	.grid-pagination__item svg path {
		transition: all .2s ease-out;
	}
	.grid-pagination__item:hover svg path {
		stroke: var(--cozh-blue);
	}
	.grid-pagination__item:not(.grid-pagination__item--current):hover {
		color: var(--cozh-blue);
	}
	.grid-pagination__item--disabled {
		pointer-events: none;
		opacity: .5;
	}


	.grid-display {
		display: flex;
		align-items: center;
	}
	.grid-display__text {
		color: var(--cozh-grey);
		font-size: 12px;
		font-weight: 500;
		margin-right: 10px;
	}


	.grid-filter {
		padding: 10px 20px;
    	border-bottom: 1px solid var(--cozh-light-grey);
    	display: flex;
    	flex-direction: row;
    	align-items: center;
    	justify-content: space-between;
	}
	.grid-filter__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}
	.grid-filter__control {
    	width: 100%;
    	min-width: 200px;
	}
	.grid-filter__control:not(:last-child) {
		margin-right: 10px;
	}
	.grid-filter__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-left: 10px;
	}
	.grid-filter__buttons button {
		margin-right: 10px;
	}
	.grid-empty {
		padding: 15px 20px;
		font-weight: 500;
		font-size: 13px;
		color: rgba(var(--cozh-black-code), .5);
		white-space: nowrap;
	}
	.grid-filter__control--checkbox {
		width: fit-content;
    	min-width: fit-content;
		margin-left: 10px;
    	margin-right: 10px;
	}


	@media (max-width: 767px) {
		.grid-pager {
			flex-direction: column-reverse;
		    align-items: center;
		    padding: 15px 20px 10px 20px;
		    height: auto;
		}
		.grid-pagination {
			margin-top: 10px;
		}
		.grid-filter {
			flex-direction: column;
		}
		.grid-filter__buttons {
			width: 100%;
			margin-top: 10px;
			margin-left: 0px;
		}
		.grid-filter__btn {
			width: 50%;
		}
		.grid-filter__container {
			flex-direction: column;
		}
		.grid-filter__control {
			width: 100%;
		}
		.grid-filter__control:not(:last-child) {
			margin-right: 0px;
			margin-bottom: 10px;
		}
		.grid-filter__control--checkbox {
			margin-top: 5px;
			margin-bottom: 5px;
			margin-left: 0px;
    		margin-right: 0px;
		}
	}
</style>