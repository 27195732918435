<template>
	<div class="load-container" :class="{'load' : isLoad}">
		<div class="block block--border" v-if="data !== null && data.dates.length > 0">
			<div class="block__header">
				<div class="block__header-left"></div>
				<div class="block__header-right">
					<div class="text-help"> {{ data.dates }}</div>
				</div>
			</div>

			<div class="block__body">
				<div class="c-pie">
					<div class="c-pie__left">
						<PieChart :chartData="chartData" :options="options" cssClasses="chart"/>
					</div>
					<div class="c-pie__right">
						<div class="c-number c-number--lg mb-2">
							<div class="c-number__content">
								<div class="c-number__num"> {{ data.total }} </div>
							</div>
							<div class="c-number__label"> Всего заданий </div>
						</div>
						<div class="c-grid">
							<div class="c-grid__col">
								<div class="c-number c-number--blue">
									<div class="c-number__content">
										<div class="c-number__num"> {{ data.done }} </div>
										<div class="c-number__percent"> {{ data.doneP + '%'}} </div>
									</div>
									<div class="c-number__label"> Сделано, всё&nbsp;хорошо </div>
								</div>
								<div class="c-number c-number--darkred">
									<div class="c-number__content">
										<div class="c-number__num"> {{ data.expired }} </div>
										<div class="c-number__percent"> {{ data.expiredP + '%'}} </div>
									</div>
									<div class="c-number__label"> Просрочено </div>
								</div>
							</div>
							<div class="c-grid__col">
								<div class="c-number c-number--orange">
									<div class="c-number__content">
										<div class="c-number__num"> {{ data.issue }} </div>
										<div class="c-number__percent"> {{ data.issueP + '%'}} </div>
									</div>
									<div class="c-number__label"> Сделано, есть&nbsp;проблема </div>
								</div>
								<div class="c-number c-number--red">
									<div class="c-number__content">
										<div class="c-number__num"> {{ data.failed }} </div>
										<div class="c-number__percent"> {{ data.failedP + '%'}} </div>
									</div>
									<div class="c-number__label"> Не сделано </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="empty-data" v-else>
		  	Нет данных
		</div>
	</div>
</template>

<script setup>
    import { PieChart } from 'vue-chart-3';
    import { Chart, registerables} from 'chart.js';
  	import ChartDataLabels from 'chartjs-plugin-datalabels';
	Chart.register(ChartDataLabels, ...registerables);
</script>

<script>

  	export default {
	    name: 'CompanyAnalyticsBar',
	    props: ['organization_id'],
        components: { PieChart },
	    data: () => ({
	    	isLoad: true,
	    	chartData: null,
	    	data: null,
	    	options: {
			    radius: '80%',
			    plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
				        color: '#fff',
				        display: true,
				        font: {
				        	size: 10,
				        	family: 'Inter',
				        	weight: 600,
				        },
				        formatter (val) {
				        	return val == 0 ? null : val + '%';
				        }
				    }
                },
			},
	    }),
	    created () {
	      	Promise.all([this.loadData()])
	      		.then(() => {})
	      		.catch(() => {})
	      		.finally(() => {
	      			this.isLoad = false;
		  		});
	    },

	    methods: {
	    	async loadData () {

	    		this.data = null;

		        // Подгружаем контент
		        const { data } = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/diagram-all-director?company_id=' + this.organization_id);


		        this.chartData = {
				  labels: [
				    'Сделано',
				    'Сделано, есть проблема',
				    'Просрочено',
				    'Не сделано',
				  ],
				  datasets: [{
				    data: [ data.doneP, data.issueP, data.expiredP, data.failedP ],
				    backgroundColor: [
				      'rgb(0 141 210)',
				      'rgb(255 145 0)',
				      'rgb(117 0 0)',
				      'rgb(224 55 18)',
				    ],
				    borderWidth: 0,
				  }]
				};

		        this.data = data;

		    },
	    },
	}
</script>

<style scoped>

	.block__body {
		padding: 0px;
	}



	.c-grid {
		display: flex;
		flex-direction: row;
		gap: 15px;
	}
	.c-grid__col {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		width: fit-content;
	}
	.c-grid__col .c-number {
		width: 100%;
	}




	.c-pie {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
	}
	.c-pie__left {
		width: 100%;
	}
	.c-pie__right {
		min-width: 50%;
	}




	.c-number {
		display: flex;
		flex-direction: column;
	}
	.c-number__content {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.c-number__num {
		font-size: 18px;
		font-weight: 600;
		line-height: 1;
	}
	.c-number__percent {
		font-size: 10px;
		font-weight: 700;
		line-height: 1;
		margin-left: 4px;
	}
	.c-number__label {
		font-size: 12px;
		font-weight: 500;
		line-height: 1.3;
		margin-top: 3px;
		/*white-space: nowrap;*/
	}

	.c-number--lg .c-number__num {
		font-size: 24px;
	}
	.c-number--lg .c-number__label {
		margin-top: 5px;
	}

	.c-number--blue .c-number__content {
	    color: var(--cozh-blue);
	}
	.c-number--red .c-number__content {
	    color: var(--cozh-red);
	}
	.c-number--darkred .c-number__content {
	    color: var(--cozh-dark-red);
	}
	.c-number--orange .c-number__content {
	    color: var(--cozh-orange);
	}


</style>